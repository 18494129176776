import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { UserContext } from "./../../../Contexts/userContext";

import { FaCog } from "react-icons/fa";

import "./SectionBottomRight.css";

const BottomRight = (props) => {
	const history = useHistory();
	const userStore = useContext(UserContext);

	const { t } = useTranslation("ui");

	return (
		<>
			<section name='Btn BottomServer' className='BottomServer d-flex'>
				<span className='BottomRight'>
					<div className='BtnGreenXs text-center'>
						<FaCog size='25px' />
					</div>
				</span>
			</section>
		</>
	);
};

export default BottomRight;
