import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";

import BtnInfo from "./../Img/Component/BtnInfo.png";

import "./../css/Page.css";

const PageFooter = () => {
	const history = useHistory();

	return (
		<>
			<div className='PageBgFooter' style={{ cursor: "default" }}>
				<div className='PageBgHeader'></div>
				<div className='PageTitleHeaderPosition d-flex'>
					<div className='PageTitleHeaderLeft'></div>
					<div className='PageTitleHeaderMain'>
						Hero-Wars Academy{" "}
						<span className='small'>
							<i className='small'>( version FB )</i>
						</span>
						<Image
							style={{ width: "20px", marginLeft: "5px", cursor: "help" }}
							src={BtnInfo}
							roundedCircle
							onClick={() => history.push("/ContentPolicy")}
						/>
					</div>
					<div className='PageTitleHeaderRight'></div>
				</div>
			</div>

			{/* <div className="PageBgHeader"></div> */}
		</>
	);
};

export default PageFooter;
