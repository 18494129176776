import React from "react";

import { useParams } from "react-router-dom";
import PageFooter from "../components/PageFooter";

import ModalcontentPolicy from "./../components/Modal/ContentPolicy";

import "./../css/Page.css";

const ContentPolicy = () => {
	const { server } = useParams("63");

	return (
		<>
			<section className='PgContentPolicy'>
				<ModalcontentPolicy show={true} serverCurrent={server} />
			</section>

			<PageFooter />
		</>
	);
};

export default ContentPolicy;
